import React from "react";
import {createRoot} from "react-dom/client";

import App from "./App";
import './index.css';

const domNd = document.getElementById('root');

const root = createRoot(domNd);

root.render(<App/>);