import { useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

import { Videos, ChannelCard } from './';
import { fetchFromYT } from '../utilis/fetchFromYT';

const ChannelDetail = () => {
  const { id } = useParams();

// {console.log(`tttt ${id}`);}
  const [channelDetail, setChannelDetail ] = useState();

  const [videos, setVideos] = useState([]);

  useEffect(()=>{
    fetchFromYT(`channels?part=snippet&id=${id}`)
    .then((data)=> setChannelDetail(data?.items[0]));
    
    fetchFromYT(`search?channelId=${id}&part=snippet%2Cid&order=date`)
    .then((data)=> {setVideos(data.items)});
  }, [id]);


  return (
    <Box minHeight='95vh'>
      <Box marginBottom={7}>
        <div style={{ 
          background: 'linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(145,36,167,1) 35%, rgba(0,212,255,1) 100%)', 
          zIndex: 10,
          height: 300
        }}>
        </div>
        <ChannelCard channelDetail={channelDetail} marginTop="-10rem" />
      </Box>
      <Box display="flex" p="2">
        <Box sx={{ mr: { sm: '10rem' } }}/>
        <Videos vid={{videos}} />
      </Box>
    </Box>
  )
}

export default ChannelDetail