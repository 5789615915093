import { Link } from 'react-router-dom';
import { Typography, Card, CardContent, CardMedia } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

import { demoThumbnailUrl, demoVideoUrl, demoVideoTitle, demoChannelUrl, demoChannelTitle } from '../utilis/constants';

const VideoCard = ({ video: {id: {videoId}, snippet } }) => {
  return (
    <Card sx={{ width: { xs:'320px'}, boxShadow: 'none', borderRadius: 0 }}> 
      <Link to={videoId ? `/video/${videoId}` : demoVideoUrl}>
        <CardMedia 
          image={snippet?.thumbnails?.high?.url} 
          alt={snippet?.title}
          sx={{width: {xs:'320px'}, height: 180 }}
        />
      </Link>
      <CardContent sx={{backgroundColor: '#1e1e1e', height: '6.625rem' }} >
        <Link to={videoId ? `/video/${videoId}` : demoVideoUrl}>
          <Typography  variant='subtitle1' fontWeight='bold' color='#fff'>
            {snippet?.title.slice(0,35).concat('...') || demoVideoTitle.slice(0,35).concat('...')}
          </Typography>
        </Link>
        <Link to={snippet?.channelId ? `/channel/${snippet?.channelId}` : demoChannelUrl}>
          <Typography variant='subtitle2' fontWeight='bold' color='#967bb6' display="flex">
            <CardMedia 
              image={snippet?.thumbnails?.high?.url} 
              alt={snippet?.title}
              sx={{width: 25, height: 25, marginRight:"0.5rem", borderRadius:"50%"}}
            />
            {snippet?.channelTitle.slice(0,60) || demoChannelTitle.slice(0,60)}
            <CheckCircle sx={{ fontSize: 12, color: 'gray', ml: '0.5rem' }} />
          </Typography>
        </Link>
      </CardContent>
    </Card>
  )
}

export default VideoCard