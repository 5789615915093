import { useState,useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import React from 'react';
import ReactPlayer from 'react-player/youtube';
import { Typography, Box, Stack, CardMedia} from '@mui/material';
import { CheckCircle } from '@mui/icons-material';

import { Videos } from './';
import { fetchFromYT } from '../utilis/fetchFromYT';

const VideoDetail = () => {
  const {id} = useParams();
  const [ videoDetail, setVideoDetail] = useState(null);

  const [ videos, setVideos] = useState([]);

  useEffect(()=>{
    fetchFromYT(`videos?part-snippert,statistics&id=${id}`)
    .then((data)=> {
      setVideoDetail(data.items[0])
    });

    fetchFromYT(`search?part=snippet&relatedToVideoId=${id}&type=videos`)
    .then((data) => {
      setVideos(data.items) 
    });
  },[id]);
  
  if(!videoDetail?.snippet) return 'Loading...';
console.log(videoDetail);
  const { snippet: {title, channelId, channelTitle}, statistics: {viewCount, likeCount} } = videoDetail;
 
  return (
    <Box minHeight="95vh">
      <Stack direction={{ xs: 'column', md: 'row'}}>
        <Box flex={1}>
          <Box sx={{ width: '100%', position:'sticky', top:'5.375rem'}}>
            <ReactPlayer url={`https://www.youtube.com/watch?v=${id}`} className="react-player" controls />
            <Typography color="#fff" p={1} variant="h5">
                {title}
            </Typography>
            <Stack direction="row" justifyContent="space-between" sx={{ color: '#fff'}} py={1} px={2}>
              <Link to={`/channel/${channelId}`}>
                <Typography varaint={{ sm: 'subtitle1', md: 'h6'}} color='#976bb6' display="flex">
                <CardMedia 
                  image={videoDetail.snippet.thumbnails.high.url} 
                  alt={videoDetail.snippet.title}
                  sx={{width: 25, height: 25, marginRight:"0.5rem", borderRadius:"50%"}}
                />
                  {channelTitle}
                  <CheckCircle sx={{ fontSize: '0.75rem', marginLeft:'0.3125rem'}} />
                </Typography>
              </Link>
              <Stack direction="row" gap="1.4rem" alignItems="center">
                <Typography variant="body1" sx={{ opacity:0.7}}>
                  {parseInt(viewCount).toLocaleString()} views
                </Typography>
                <Typography variant="body1" sx={{ opacity:0.7}}>
                  {parseInt(likeCount).toLocaleString()} likes
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Box>
        <Box px={2} py={{ md:1, xs:5}} justifyContent="center" alignItems="center">
            <Videos vid={videos} direction="column"/>
        </Box>
      </Stack>
    </Box>
  )
}

export default VideoDetail