import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Paper, IconButton } from '@mui/material';
import { NotificationsActiveTwoTone, Search } from '@mui/icons-material';

const SearchBar = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate();

    const handleSubmit = (e) =>{
        e.preventDefault();

        if(searchTerm){
            navigate(`/search/${searchTerm}`);
        }
        setSearchTerm('');
    }

  return (
    <Paper
        component="form"
        onSubmit={handleSubmit}
        sx={{
            borderRadius: 20,
            border: '0.1rem solid #e3e3e3',
            pl: 2,
            boxShadow: 'none',
            mr: { sm: 5 }
        }}
    >
        <input 
            className='search-bar'
            placeholder='Search...'
            value={searchTerm}
            onChange={(e)=>{setSearchTerm(e.target.value)}}
        />
        <IconButton type='submit' 
            sx={{ p: '0.5rem 1rem', 
            color: 'black', 
            }}>
            <Search />
        </IconButton>
    </Paper>
  )
}

export default SearchBar