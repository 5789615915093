import { useState, useEffect} from 'react';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';

import { Videos } from './';

import { fetchFromYT } from '../utilis/fetchFromYT';

const SearchFeed = () => {
  const { searchTerm } = useParams();
  const [videos, setVideos] = useState([]);

  useEffect(()=>{
    fetchFromYT(`search?part=snippet,id&q=${searchTerm}`)
    .then((data)=> setVideos(data?.items));
  },[searchTerm]);

  return (
    <Box p={2} sx={{
      overflowY: 'auto',
      height: '90vh',
      flex: 2
    }}>
      <Typography
        variant='h4'
        fontWeight="bold"
        mb={2} 
        sx={{
          color: 'white'
        }}
      >
        Search Results for: <span style={{
          color: '#967bb6', textTransform: 'capitalize'
        }}>
          {searchTerm}
        </span> Videos
      </Typography>
      <Videos vid={{videos}} />
    </Box>
  )
}

export default SearchFeed