import { Typography, Card, Stack, Box, CardMedia, CardContent } from '@mui/material';
import { VideoCard, ChannelCard } from './';
import { Link } from 'react-router-dom';
import { CheckCircle } from '@mui/icons-material';


import { demoVideoTitle, demoProfilePicture,  demoChannelUrl, demoChannelTitle } from '../utilis/constants';

const Videos =  ({ vid, dire }) => {
  console.log(vid);
  if(dire==="column"){
      
  }else{
      vid = vid?.videos
  }
  
  return (
    <Stack
      direction={ `${dire}` || "row" }
      flexWrap="wrap"
      justifyContent="start"
      gap={2}
    >
         
        {vid?.map((item, indx) => (
        <Box key={indx}>
          {item.id.videoId && <VideoCard video={item} /> }
          {item.id.channelId && <ChannelCard channelDetail={item} /> }
          {item.id.playlistId ? 
          <Card sx={{ width: { xs:'320px'}, boxShadow: 'none', borderRadius: 0 }}>
            <Link to={item?.snippet?.channelId ? `/channel/${item?.snippet?.channelId}` : demoChannelUrl}>
              <CardMedia 
                 image={item?.snippet?.thumbnails?.high?.url || demoProfilePicture } 
                 alt={demoVideoTitle}
                 sx={{ width: { xs:'320px'}, height: 180, mb: 0, border: '0.1rem solid #e3e3e3' }}
               />
               <CardContent sx={{backgroundColor: '#1e1e1e', height: '6.625rem' }} >
                 <Typography  variant='subtitle1' fontWeight='bold' color='#fff'>
                   {item?.snippet?.title.slice(0,35).concat('...') || demoVideoTitle.slice(0,35).concat('...')}
                 </Typography>       
                 <Typography  variant='subtitle2' fontWeight='bold' color='#967bb6'>
                   {item?.snippet?.channelTitle.slice(0,60) || demoChannelTitle.slice(0,60)}
                   <CheckCircle sx={{ fontSize: 12, color: 'gray', ml: '0.5rem' }} />
                 </Typography>
             </CardContent>
             </Link>
            </Card>
            : 
            <div style={{display: 'none' }} />
           
          }
        </Box>
      ))}
    </Stack>
  )
}

export default Videos