import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { CheckCircle } from '@mui/icons-material';
import { Link, useParams } from 'react-router-dom';

import { demoProfilePicture } from '../utilis/constants';



const ChannelCard = ({ channelDetail, marginTop }) =>  {

  const { id } = useParams();

  return (
  
    <Box
      sx={{
        boxShadow: 'none',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: { xs:320 },
        height: 326, 
        margin: 'auto',
        backgroundColor: 'whitesmoke',
        marginTop,
      }}
    >
      <Link to={channelDetail?.id?.channelId ? `/channel/${channelDetail?.id?.channelId}` : `/channel/${id}`}>
        <Card sx={{ display: 'flex', flexDirection: 'column', justifyContent:'center', textAlign:'center', color: '#fff'}}>
          <CardMedia 
            image={channelDetail?.snippet?.thumbnails?.high?.url || demoProfilePicture } 
            alt={channelDetail?.snippet?.title}
            sx={{ borderRadius: '50%', width: 180, height: 180, mb: 2, border: '0.1rem solid #e3e3e3' }}
          />
          <Typography variant="h6" color="#976bb6" >
            { channelDetail?.snippet?.title }
            <CheckCircle sx={{ fontSize: 14, color: 'gray', ml: '0.5rem' }} />
          </Typography>
            { channelDetail?.statistics?.subscriberCount && (
              <Typography color="#000" fontWeight="bold">
                {parseInt(channelDetail?.statistics?.subscriberCount).toLocaleString()} Subscribers
              </Typography>
            )}
        </Card>      
      </Link>
    </Box>
  )
}


export default ChannelCard